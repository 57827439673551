.btncolor {
    background-color: #2DCADF;
    border-radius: 55555px;
    color: #ffff;
    margin-bottom: 2rem;
}

.package {
    list-style-type: none;
}

.parent {
    display: flex;
    border: 2px solid yellow;
}

.child {
    background-color: red;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    color: white;
}

.icon-color {
    color: #1f38fa;
}